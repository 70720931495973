/* App.css */
.university-container{
  background-image: url(./../../Images/university/redAbstract1.jpg);
    /* background-color: rgb(255, 248, 248); */
    background-size: cover;
    padding: 10px 20px;
    /* border: 1px solid red; */
    box-shadow: 2px 2px 5px #dd0e3a;
    border-radius: 20px;
}

.university-heading{
    text-align: center;
    font-family: "Metropolis-Medium", sans-serif;
    color: #525252;
    padding: 30px 0px;
}
.swiper-container {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0px;
  }
  
  .swiper-slide img {
    width: 200px; /* Adjust this size as needed */
    height: auto;
  }

  .swiper-pagination-bullet-active{
    background: #dd0e3a;
  }

  @media screen and (max-width: 480px) {
   .university-container{
    width: 250px;
    height: 320px;
   }
   .university-swiper .swiper-slide img{
    width: 80px;
    height: auto;
   }
   .university-swiper .swiper-pagination-bullet{
    display: none;
  }
  }

  
  