.course-head-part {
  padding: 20px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Metropolis-Medium", sans-serif;
}

.course-head {
  color: #dd0e3a;
}

.course-filter-btn {
  background-color: #ffffff;
  color: #dd0e3a;
  border-radius: 10px;
  border: 2px solid #dd0e3a;
  padding: 5px 30px;
}

.course-item {
  background-color: rgb(221, 14, 58,0.2);
  width: 100%;
  border-right: 5px solid #dd0e3a;
  font-weight: 600;
  
}

.course-link{
    width: 200px;
    text-decoration: none!important;
    cursor: pointer;
    border:none!important;
    background-color: rgb(255, 255, 255,0.01)!important;
}

.course-canvas {
  width: 800px !important;
}

.subject-canvas{
    width: 800px !important;
}

#courseTabs {
  border-color: none;
  border-bottom: none;
}

#courseTabs a{
    text-decoration: none!important;
    color: #000000;
}

.filter-select{
    width: 300px;
    margin: 0px 20px;
}

.filter-left{
    position: -webkit-sticky; /* For Safari */
    position: sticky;
    top: 0px;
}

.course_subject{
    cursor: pointer;
}

.course-branch{
  font-family: "Metropolis-Medium", sans-serif;
margin-bottom: 10px;
font-size: 17px;
}

.subject_link{
    text-decoration: none;
    color: #000000;
    font-family: "Metropolis-Medium", sans-serif;
    font-size: 17px;
    cursor: pointer;
}

.subject_head{
    font-family: "Metropolis-Medium", sans-serif;
    font-size: 17px;
    color: #dd0e3a;
}

.subject_check{
    cursor: pointer;
    border-color: #dd0e3a;
}
.subject_box{
    padding-left: 20px;
}
.subject_link:hover{
    color: #dd0e3a;
}
.filter-footer{
    padding: 20px 10px;
    display: flex;
    align-items: center;
    justify-content:space-around;
}

.filter-btn{
    all: unset;
    border: 2px solid #dd0e3a;
    color: #dd0e3a;
    font-family: "Metropolis-Medium", sans-serif;
    padding: 5px 20px;
    border-radius: 10px;
}

.filter-btn:hover{
    background-color: #dd0e3a;
    color: white;
}

.course-card{
    height: 350px;
    padding: 10px 15px;
}

.course-title{
    color: #000000;
    font-family: "Metropolis-Bold", sans-serif;
}

.course-title-details{
    height: 80px;
}
.course-details-list {
    padding: 0px;
}
.course-details-list li{
    padding: 10px 0px;
    list-style-type: none;
    text-decoration: none;
}