/* In your main CSS file (e.g., App.css) */
@import "swiper/css";

body {
  background-color: white;
}

@font-face {
  font-family: "Metropolis-Light";
  src: url(../src/fonts/Metropolis-Thin.otf);
}

@font-face {
  font-family: "Metropolis-Black";
  src: url(../src/fonts/Metropolis-Black.otf);
}

@font-face {
  font-family: "Metropolis-Black-italic";
  src: url(../src/fonts/Metropolis-BlackItalic.otf);
}

@font-face {
  font-family: "Metropolis-SemiBold";
  src: url(../src/fonts/Metropolis-SemiBold.otf);
}

@font-face {
  font-family: "Metropolis-Bold";
  src: url(../src/fonts/Metropolis-Bold.otf);
}

@font-face {
  font-family: "Metropolis-Medium";
  src: url(../src/fonts/Metropolis-Medium.otf);
}

@font-face {
  font-family: "Number";
  src: url(../src/fonts/AucklandHills-6YEYM.ttf);
}


input[type="text"],input[type="email"],input[type="phone"],textarea{
  border: 2px solid rgb(143, 20, 9,0.3); /* Default border color */
  border-radius: 8px; /* Rounded corners */
  padding: 8px; /* Padding inside the input field */
  font-size: 16px; /* Font size */
  outline: none; /* Remove default outline */
  transition: border-color 0.3s ease; /* Smooth transition for border color */
}

/* Style for the input field when it is focused */
input[type="text"]:focus, textarea:focus,input[type="email"]:focus ,input[type="phone"]:focus{
  border-color: #8f1409; /* Change border color on focus */
  box-shadow: 0 0 5px rgb(143, 20, 9,0.5); /* Optional: Add a subtle shadow */
}

input[type="text"]::placeholder, textarea::placeholder,input[type="email"]::placeholder,input[type="phone"]::placeholder {
  color: #8f1409; /* Placeholder text color */
  font-family: "Metropolis-Light", sans-serif;

}

textarea {
  height: 100px; /* Set a fixed height for the textarea */
  resize: vertical; /* Allow vertical resizing */
}

.code-red{
  color: #dd0e3a;
}

.code-select{
  border: 2px solid #8f1409;
}

.code-not-select{
  border: 2px solid rgb(143, 20, 9,0.3)
}

.font-semi{
  font-family: "Metropolis-SemiBold", sans-serif;
}

.font-medium{
  font-family: "Metropolis-Medium", sans-serif;
}

.font-bold{
  font-family: "Metropolis-Bold", sans-serif;
}
.font-light{
  font-family: "Metropolis-light", sans-serif;
}

img {
  -webkit-user-drag: none;
  user-drag: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
