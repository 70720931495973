.contact_head{
    font-family: "Metropolis-Medium", sans-serif;
    color:#2c2c54;
}

.contact_card{
    height: 200px;
    padding: 20px 5px;
    background-color: rgb(255, 255, 255);
    border: none;
    background-image: linear-gradient(to left top, #dd0e3a,#ffffff,#ffffff,#ffffff,#ffffff,#ffffff,#ffffff ,#ffffff);
    box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;
}

.contact_card h5{
    font-family: "Metropolis-Medium", sans-serif;
    color: #dd0e3a;
}

.contact-tel{
    text-decoration: none;
    color: #dd0e3a;
}

.loc-ico{
    width: 40px;
    height: 40px;
    margin: 0px 10px;
}

.contact-text{
    padding-left: 60px;
    padding-top:0px ;
    padding-bottom:0px ;
    margin: 0px;
    font-weight: 500;
    font-family: "Metropolis-Medium", sans-serif;
    color:#6c6c6c;
}

.contact-link{
    list-style: none;
    color:#6c6c6c;
    list-style-type: none;
    text-decoration: none;
}

.contact-link:hover{
    color: #dd0e3a;
}

.map-des{
    text-align: center;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 15px 25px, rgba(0, 0, 0, 0.05) 0px 5px 10px;
}

.form-border{
   
    width: 100%;
    margin: auto;
    padding: 30px;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.responsive-iframe {
    width: 100%;
    height: 100%;
    border: 0;
}

.form-heading{
    text-align: center;
    color: #dd0e3a;
    font-family: "Metropolis-Medium", sans-serif;

}

@media (max-width: 600px) {
    /* Mobile screens */
    .map-des {
        width: 100%;
        height: 300px;
    }
}

@media (min-width: 601px) and (max-width: 1024px) {
    /* Tablet screens */
    .map-des {
        width: 100%;
        height: 400px;
    }
}

@media (min-width: 1025px) {
    /* Laptop and larger screens */
    .map-des {
        width: 625px;
        height: 450px;
    }
}