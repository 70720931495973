.chat-main{
    position: fixed;
    bottom: 80px;
    right: 20px;
    z-index: 10000;
}

.chat-icon{
    position: fixed;
    bottom: 20px;
    right: 20px;
}

.chat-icon img{
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.chat-minimize-btn{
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1000;
}

.chat-minimize-btn img{
    width: 25px;
    cursor: pointer;
}

.mini-btn{
    all: unset;
    color: white;
}

.react-chatbot-kit-chat-header{
    background-color: #dd0e3a!important;
    color: white!important;
}
.react-chatbot-kit-chat-message-container{
    background-color: #ffffff;
}

.react-chatbot-kit-chat-container{
    width: 300px!important;
}

.react-chatbot-kit-chat-bot-message{
    background-color: #dd0e3a!important;
    margin-left: 4px!important;
}

.react-chatbot-kit-chat-bot-message-arrow{
    border-right: 8px solid #dd0e3a!important;
}
.react-chatbot-kit-chat-btn-send{
    background-color: #dd0e3a!important;
}

.sec-msg{
    background-color: #dd0e3a;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    width: 90%;
}
.options-container {
    padding-left: 54px;
  }
  
  .option-button {
    margin: 5px;
    padding: 5px 20px;
    background-color: #ffffff;
    color: #dd0e3a;
    border: none;
    border: 1px solid #dd0e3a;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .option-button:hover {
    background-color: #dd0e3a;
    color: white;
  }

  .noti-parent{
    position: relative;
  }

  .notify-msg{
    position: absolute;
    top: -15px;
    right: 2px;
    background-color: #dd0e3a;
    color: white;
    padding: 2px 8px;
    border-radius: 50%;

  }


  @media (max-width: 768px) {
    .react-chatbot-kit-chat-container {
        width: 100% !important;
        bottom: 0;
        right: 0;
    }

    .chat-icon img {
        width: 40px;
        height: 40px;
    }

    .chat-minimize-btn img {
        width: 20px;
    }
}

/* Mobile */
@media (max-width: 480px) {
    .chat-main {
        bottom: 60px;
        right: 10px;
    }

    .chat-icon {
        bottom: 10px;
        right: 10px;
    }

    .chat-icon img {
        width: 35px;
        height: 35px;
    }

    .react-chatbot-kit-chat-container {
        width: 100% !important;
        bottom: 0;
        right: 0;
    }

    .chat-minimize-btn img {
        width: 18px;
    }

    .sec-msg {
        width: 80%;
    }

    .options-container {
        padding-left: 30px;
    }

    .option-button {
        padding: 5px 15px;
    }
}

/* Desktop */
@media (min-width: 769px) {
    .react-chatbot-kit-chat-container {
        width: 400px !important;
    }

    .chat-icon img {
        width: 50px;
        height: 50px;
    }

    .chat-minimize-btn img {
        width: 25px;
    }
}