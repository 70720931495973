.service-head{
    text-align: center;
    padding-top: 50px;
    margin-top: 20px;
}

.service-topic{
    font-family:"Metropolis-SemiBold", sans-serif;
    font-weight: 900;
    color: #10154e;
}

.service-content{
    font-family:"Metropolis-SemiBold", sans-serif;
    color: #818181;
    font-size: 20px;
    word-spacing: 0px;
    width: 80%;
    margin: auto;
    line-height:40px;

}

.service-main{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

  .service-card{
    width: 25%;
    height: 150px;
    margin: 20px 10px;
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
  }

  .service-card:hover{
    box-shadow: 2px 2px 5px #dd0e3a;
    cursor: pointer;
  }
.img-card{
    width: 100px;
    height: 100px;
    background: #ffffff;
    padding: 5px;
    border-radius: 50%;
}

.service-card-content h2{
    font-family:'Metropolis-SemiBold', sans-serif;
    font-size: 25px;
    padding: 0px 0px;
    color: #10154e;
}

/* .service-card-content p{
    font-family:'Metropolis-SemiBold', sans-serif;
    font-size: 15px;
    font-weight: 500;
    color:  #818181;
} */

.service-link{
    color:#dd0e3a ;
    font-family:'Metropolis-SemiBold', sans-serif; ;
}


@media screen and (max-width: 1024px) {
    .service-head {
      padding: 80px 0px;
    }
  
    .service-content {
      font-size: 18px;
      line-height: 35px;
    }
  
    .service-card {
      width: 40%;
      margin: 20px 10px;
    }
  
    .service-card-content h2 {
      font-size: 22px;
    }
  }
  
  /* Tablet */
  @media screen and (max-width: 768px) {
    .service-head {
      padding: 60px 0px;
    }
  
    .service-content {
      font-size: 16px;
      line-height: 30px;
    }
  
    .service-card {
      width: 45%;
      margin: 15px 10px;
    }
  
    .service-card-content h2 {
      font-size: 20px;
    }
  
    .img-card {
      width: 80px;
      height: 80px;
    }
  }
  
  /* Mobile */
  @media screen and (max-width: 480px) {
    .service-head {
      padding: 40px 0px;
    }
  
    .service-content {
      font-size: 14px;
      line-height: 25px;
      width: 90%;
    }
  
    .service-main {
      flex-direction: column;
      align-items: center;
    }
  
    .service-card {
      width: 90%;
      margin: 0px 0px;
    }
  
    .service-card-content h2 {
      font-size: 20px;
    }
  
    .img-card {
      width: 70px;
      height: 70px;
    }
  }