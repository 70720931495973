.apply-head h3{
    font-family: "Metropolis-Bold", sans-serif;
}
.apply-head p{
    font-family: "Metropolis-Light", sans-serif;
}

.head-under{
 border: 2px solid #dd0e3a;
 border-radius: 10px;
 width: 8%;
 margin: 0px;
 opacity: 0.9;
}
.name-sec{
    display: flex;
    justify-content:space-between ;
    font-family: "Metropolis-SemiBold", sans-serif;
}

.apply-input-size{
    width: 310px;
    height: 40px;
    border-radius: 10px;
    border: 1px solid #dd0e3a;
}

.apply-input-size-mobile{
    text-align: center;
    width: 85px;
    height: 40px;
    border-radius: 10px;
    border: 1px solid #dd0e3a;
    padding-right: 10px;
    margin-right: 10px;
}
.apply-input-size-mobile-full{
    width: 85%;
    height: 40px;
    border-radius: 10px;
    border: 1px solid #dd0e3a;
}

.apply-input-size:active{
    border: 1px solid #dd0e3a;
}

.apply-input-size-full{
    width: 100%;
    height: 40px;
    border-radius: 10px;
    border: 1px solid #dd0e3a;
}

.apply-select-box-size{
    width: 310px;
}

.form-box{
    margin-bottom: 50px;
}

.apply-page-btn{
    border-radius: 20px;
    padding: 10px 50px;
    border: none;
    font-family: "Metropolis-SemiBold", sans-serif;
    background-color: #ff464c;
    color: white;
    background: linear-gradient(to right, #dd0e3a 50%, #dd0e3a 50%);
    background-size: 200% 100%;
    background-position: left bottom;
    transition: background-position 0.3s, color 0.3s;
  }

  .apply-page-btn:hover{
    background-position: right bottom;
    color: #ffffff;
  }



  /* Mobile view (max-width: 767px) */
@media (max-width: 767px) {
    
  
    .name-sec {
      display: block;
    }
  
    .apply-input-size,
    .apply-select-box-size,
    .apply-input-size-mobile-full,
    .apply-input-size-full {
      width: 100%;
    }
  
    .apply-input-size-mobile {
      width: 100%;
      margin-right: 0;
      margin-bottom: 10px;
    }
  
    .pe-3 {
      padding-right: 0;
    }
  
    .d-flex {
      flex-direction: column;
    }
  
    .form-box {
      margin-bottom: 20px;
    }
  
    .apply-page-btn {
      width: 100%;
      padding: 10px;
    }

    .right-img{
        display: none;
    }
  }
  
  /* Tablet view (min-width: 768px and max-width: 1024px) */
  @media (min-width: 768px) and (max-width: 1024px) {
    
  
    .name-sec {
      display: flex;
      flex-wrap: wrap;
    }
  
    .apply-input-size,
    .apply-select-box-size,
    .apply-input-size-mobile-full,
    .apply-input-size-full {
      width: 100%;
    }
  
    .apply-input-size-mobile {
      width: 20%;
      margin-right: 5%;
    }
  
    .pe-3 {
      padding-right: 0;
    }
  
    .d-flex {
      flex-direction: column;
    }
  
    .form-box {
      margin-bottom: 30px;
    }
  
    .apply-page-btn {
      width: 100%;
      padding: 10px;
    }
  }
  
  /* Laptop view (min-width: 1025px and max-width: 1366px) */
  @media (min-width: 1025px) and (max-width: 1366px) {
    
  
    .name-sec {
      display: flex;
      flex-wrap: nowrap;
    }
  
    .apply-input-size,
    .apply-select-box-size,
    .apply-input-size-mobile-full,
    .apply-input-size-full {
      width: 100%;
    }
  
    .apply-input-size-mobile {
      width: 15%;
      margin-right: 5%;
    }
  
    .pe-3 {
      padding-right: 15px;
    }
  
    .d-flex {
      flex-direction: row;
    }
  
    .form-box {
      margin-bottom: 40px;
    }
  
    .apply-page-btn {
      width: auto;
      padding: 10px 30px;
    }
  }
  
  /* Desktop view (min-width: 1367px) */
  @media (min-width: 1367px) {
    
  
    .name-sec {
      display: flex;
      flex-wrap: nowrap;
    }
  
    .apply-input-size,
    .apply-select-box-size,
    .apply-input-size-mobile-full,
    .apply-input-size-full {
      width: 310px;
    }
  
    .apply-input-size-mobile {
      width: 85px;
      margin-right: 10px;
    }
  
    .pe-3 {
      padding-right: 30px;
    }
  
    .d-flex {
      flex-direction: row;
    }
  
    .form-box {
      margin-bottom: 50px;
    }
  
    .apply-page-btn {
      width: auto;
      padding: 10px 50px;
    }
  }
  
