
  
  .text-content {
    max-width: 95%;
    margin: 60px 0px;
  }
  
  .text-content h1 {
    font-size: 2.5em;
    margin: 0;
    font-weight: 700;
    font-family: "Metropolis-Black", sans-serif;
  }
  
  .text-content .highlight {
    color: #dd0e3a;
  }
  
  .text-content p {
    font-size: 1.2em;
    margin: 20px 0;
    color: #818181;
    font-weight: 300;
    word-spacing: 0px;
    font-family: "Metropolis-Medium", sans-serif;
  }
  
  .apply-button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: 500;
    font-size: 16px;
    font-family: "Metropolis-Medium", sans-serif;
    background-color: #dd0e3a;
    color: white;
    background: linear-gradient(to right, #dd0e3a 50%, #df3459 50%);
    background-size: 200% 100%;
    background-position: left bottom;
    transition: background-position 0.3s, color 0.3s;
  }
  
  .apply-button:hover {
    background-position: right bottom;
  color: #ffffff;
  }
  
  .social-icons {
    margin-top: 100px;
  }
  
  .social-icons a {
    margin-right: 30px;
    color: #333;
    text-decoration: none;
    font-size: 1.5em;
  }

 .f-color{
    color: #1877F2;
  }
  .t-color{
    color: #1DA1F2;
  }

  .i-color{
    color: #FCAF45;
  }
  
  .image-content img {
    max-width: 100%;
    height: 550px;
  }

  .graduation-banner{
    position: relative;
    width:100%;
    height: 550px;
    overflow: hidden;
  }



  .banner-bg{
    width: 450px;
    height: 450px;
    background-color:#dd0e3a;
    border-radius: 50%;
    position: absolute;
    top: 100px;
    right: 50px;
    overflow: hidden;
  }

  .image-content img{
    width: 800px;
    height: 800px;
    object-fit: contain;
    object-position: 100% 10%;
  }




 /* Laptop */
@media screen and (max-width: 1024px) {
  .text-content h1 {
    font-size: 3em;
  }

  .text-content p {
    font-size: 1em;
  }

  .apply-button {
    padding: 10px 30px;
    font-size: 18px;
  }

  .social-icons a {
    margin-right: 20px;
    font-size: 1.2em;
  }

  .banner-bg {
    width: 450px;
    height: 450px;
    top: 120px;
    right: 10px;
  }

  .image-content {
    right: 60px;
  }
}

/* Tablet */
@media screen and (max-width: 768px) {
  .text-content h1 {
    font-size: 2.5em;
  }

  .text-content p {
    font-size: 0.9em;
  }

  .apply-button {
    padding: 8px 25px;
    font-size: 16px;
  }

  .social-icons {
    margin-top: 80px;
  }

  .social-icons a {
    margin-right: 15px;
    font-size: 1em;
  }

  .banner-bg {
    width: 350px;
    height: 350px;
    top: 100px;
  }

  .image-content {
    right: 40px;
  }
}

/* Mobile */
@media screen and (max-width: 480px) {
  .text-content{
    text-align: center;
    padding: 0px 10px;
    width: 100%;
    margin: 20px auto;
  }

  .text-content h1 {
    font-size:30px;
  }

  .text-content p {
    font-size: 0.8em;
  }
  .home-btn{
    width: 50%;
    margin: auto;
  }

  .apply-button {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 6px 10px;
    font-size: 14px;
  }

  .social-icons {
    width: 80%;
    margin:30px auto ;
  }

  .social-icons a {
    font-size: 0.8em;
  }

  .banner-bg {
    width: 250px;
    height: 250px;
    background-color:#dd0e3a;
    border-radius: 50%;
    position: absolute;
    top: 80px;
    right: 0px;
    overflow: hidden;
  }

  .image-content {
    top: 20px;
    right: 10px;
  }
  .image-content img{
    width: 350px;
    height: 310px;
  }

  .graduation-banner{
    content: "";
    width: 80%;
    margin: auto;
    overflow: auto;
    text-align: center;
    height: 350px;
  }
}