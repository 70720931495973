/* Navbar.css */
.navbar-sticky-top{
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 0;
  z-index: 1000;
}

.navbars {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px ;
  background-color: white;
  max-width: 100%;
  
}

.navbar-logo {
  display: flex;
  align-items: center;
  cursor:pointer;
}

.navbar-logo img {
  height: 40px;
  margin-right: 10px;
}

.navbar-title {
  font-size: 24px;
  font-weight: bold;
  color: #2c2c54;
}

.navbar-subtitle {
  font-size: 12px;
  color: #707070;
  margin-left: 5px;
}

.navbar-links {
  display: flex;
  align-items: center;
  cursor: pointer!important;
}

.navbar-link {
  margin: 0 20px;
  text-decoration: none;
  color: #10154e;
  font-size: 16px;
  position: relative;
  font-family: "Metropolis-SemiBold", sans-serif;
}

.navbar-link::after {
  content: "";
  display: block;
  width: 0;
  height: 2px;
  background-color: #dd0e3a;
  position: absolute;
  bottom: -5px;
  left: 0;
  transition: width 0.3s ease-in-out;
  cursor: pointer;
}

.navbar-link:hover::after {
  width: 100%;
  cursor: pointer;
}

.navbar-link.active::after {
  width: 100%;
  cursor: pointer;
  color:#dd0e3a!important;
}

.navbar-link.active::after {
  content: "";
  display: block;
  width: 60%;
  height: 2px;
  background-color: #dd0e3a;
  position: absolute;
  bottom: -5px;
  left: 0;
  color:#dd0e3a;
}


.navbar-apply-button {
  padding: 7px 30px;
  margin: 0 0px 0px 60px ;
  border-radius: 5px;
  text-decoration: none;
  font-size: 16px;
  background-color: #dd0e3a;
  color: white;
  background: linear-gradient(to right, #dd0e3a 50%, #dd0e3a 50%);
  background-size: 200% 100%;
  background-position: left bottom;
  transition: background-position 0.3s, color 0.3s;
  font-family: "Metropolis-SemiBold", sans-serif;
}

.navbar-login-button {
  padding: 7px 30px;
  margin: 0 0px 0px 10px ;
  border-radius: 5px;
  text-decoration: none;
  font-size: 16px;
  background-color: #dd0e3a;
  color: white;
  background: linear-gradient(to right, #dd0e3a 50%, #dd0e3a 50%);
  background-size: 200% 100%;
  background-position: left bottom;
  transition: background-position 0.3s, color 0.3s;
  font-family: "Metropolis-SemiBold", sans-serif;
}

.navbar-apply-button:hover {
  cursor: pointer;
  background-position: right bottom;
  color: #ffffff;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  gap: 0.3rem;
  padding-right: 20px;
}

.hamburger .bar {
  width: 24px;
  height: 2px;
  background: #000;
  transition: all 0.3s ease;
}

/* .menu-item {
  position: relative;
}

.sub-menu {
  display: none; 
  position: absolute;
  top: 40px;
  left: 50px;
  padding: 15px;
  background-color: #dd0e3a; 
  border-radius: 0px 10px 10px 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sub-menu::before {
  content: '';
  position: absolute;
  top: -10px;
  left: 35px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #dd0e3a;
}

.sub-menu-item {
  padding: 7px 20px;
  color: white; 
  background-color: #dd0e3a;
  cursor: pointer;
  font-family: "Metropolis-SemiBold", sans-serif;
  width: 250px;
  transition: all 0.5s ease-out;
}

.sub-menu-item:hover {
  padding-left: 30px;
  transform: scale(1.1);
  transition: all 0.5s ease-out;
} */

/* Define keyframes for the submenu animation */
@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(-10px);
  }
}

/* Styles for the menu item and submenu */
.menu-item {
  position: relative;
}

.sub-menu {
  position: absolute;
  width: 250px; /* Adjust as needed */
  color: white;
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 0.3s ease, transform 0.3s ease;
  visibility: hidden; /* Initially hidden */
  z-index: 1000;
  top: 10px;
  margin-top: 20px;
  left: 50px;
  padding: 15px;
  background-color: #dd0e3a; 
  border-radius: 0px 10px 10px 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.menu-item:hover .sub-menu {
  opacity: 1;
  transform: translateY(0);
  visibility: visible; 
  transition: opacity 0.3s ease, transform 0.3s ease;
}

/* Additional styles for submenu items */
.sub-menu-item {
  padding: 7px 20px;
  color: white; 
  cursor: pointer;
  font-family: "Metropolis-SemiBold", sans-serif;
  transition: all 0.5s ease-out;
}

.sub-menu-item:hover {
  padding-left: 30px;
  transform: scale(1.1);
  transition: all 0.5s ease-out;
}


.menu {
  display: none;
  position: absolute;
  top: 60px;
  right: 10px;
  background: white;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 10px;
  z-index: 1000;
}

.menu.open {
  display: block;
}


.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  width: 280px;
}

.menu li {
  margin: 10px 0;
}

.menu a {
  text-decoration: none;
  color: #333;
  font-family: "Metropolis-SemiBold", sans-serif;
}

.nav-sub-menu{
  transition: width 0.3s ease, height 0.3s ease;
}

.submenu-li {
  width: 100%;
  height: 0;
  opacity: 0;
  background-color: #801A1E;
  overflow: hidden;
  transition: height 0.3s ease, opacity 0.3s ease;
}

.nav-sub-menu:hover .submenu-li {
  opacity: 1;
  height: 250px;
}



.hamburger.open .bar1 {
  width: 18px;
  transform: rotate(-45deg) translate(-6px, 4px);
}

.hamburger.open .bar2 {
  opacity: 0;
}

.hamburger.open .bar3 {
  width: 18px;
  transform: rotate(45deg) translate(-5px, -4px);
}





@media (max-width: 768px) {
  .navbar-links {
    display: none;
    flex-direction: column;
    gap: 0;
    position: absolute;
    top: 70px;
    left: 0;
    background: #fff;
    width: 100%;
    text-align: center;
    border-top: 1px solid #ddd;
  }

  .navbar-links.open {
    display: flex;
  }

  .navbar-link,
  .navbar-apply-button {
    width: 100%;
    text-align: left;
    padding: 1rem;
    border-bottom: 1px solid #ddd;
    color: rgb(247, 0, 0)!important ;
  }

  .hamburger {
    display: flex;
  }

  

  @keyframes slideIn {
    from {
      left: -100%;
    }
    to {
      left: 0;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .navbar {
    flex-wrap: nowrap;
  }
  .navbar-link {
    margin: 0 20px;
    text-decoration: none;
    color: #000;
    font-size: 12px;
    position: relative;
    font-family: "Metropolis-SemiBold", sans-serif;
  }

 .navbar-apply-button {
    padding: 4px 10px;
    margin: 0 10px;
    border: 2px solid #dd0e3a;
    border-radius: 10px;
    color: #dd0e3a;
    text-decoration: none;
    font-size: 12px;
    transition: background-color 0.3s, color 0.3s;
  }
  
}
